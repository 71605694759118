
import Vue from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';
import { Proposal } from '@/shared/types';

import Slide from '@/components/Output/Slide.vue';

import { SlideVisibility } from '@/app/graphql';
import { Models } from '@/injectables/tokens';
import { OutputModelContract } from '@/injectables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).renderedSlideIDs = {};

export default Vue.extend({
  name: 'sharedOutput',

  components: {
    Slide,
  },

  useInjectable: [Models.Output],

  data: (): {
    colors: object;
    loading: boolean;
    exporting: boolean;
    exportingPageHeight: number;
    exportingPageWidth: number;
    proposal: Proposal | null;
    currentScreenshotSlideId: string | null;
  } => ({
    proposal: null,
    loading: true,
    exporting: false,
    exportingPageHeight: 816, // 1224,  <- 1224 is too 'tall' when 'printing', don't know why yet
    exportingPageWidth: 1584,
    colors: {
      primary: '#7DAED3',
      secondary: undefined,
      background: '#000000',
      text: '#FFFFFF',
      textAccent: '#7DAED3',
      mapAccent: '#304FED',
    },
    currentScreenshotSlideId: 'none',
  }),

  computed: {
    // switching to these will preserve aspect ratio for pdf and maintain aspect ratio for sharedoutput link
    // but will still look really skewed
    // exportingPageHeight(): number {
    //   return this.$store.state.output.screenWidth * 0.515;
    // },
    // exportingPageWidth(): number {
    //   return this.$store.state.output.screenWidth;
    // },
    // TODO: use ctx.data.ouput instead of getter?
    slides(): OutputSlide[] {
      const filterHiddenSlides = (slides: OutputSlide[]): OutputSlide[] => {
        return slides.filter(s => s?.visibility === SlideVisibility.Visible);
      };
      if (this.$store.getters['output/allLocalSlides'].length)
        return filterHiddenSlides(this.$store.getters['output/allLocalSlides']);
      return [];
      // if (this.currentScreenshotSlideId) {

      //   return slides.filter((slide: any) => slide._id === this.currentScreenshotSlideId);
      // }
    },
    agencyColors() {
      return this.$store.state.output.localLayoutColors;
    },
    colorStyles() {
      return (this[Models.Output] as OutputModelContract).getColorStyles(this.agencyColors);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentScreenshotSlide(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const found = this.slides.find((slide: any) => slide._id === this.currentScreenshotSlideId);
      return found;
    },
  },

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).renderedSlideIDs = {};

    this.exporting = !!this.$route.meta?.exporting || !!this.$route.query?.exportPDF;

    // console.log({ isShort: this.$route.meta.isShort });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {};
    if (this.exporting && this.$route.params?.token) {
      payload.token = this.$route.params?.token;
      payload.proposalId = this.$route.params.shortId;
      // payload.exporting = true;
      payload.exporting = !!this.$route.meta?.exporting;
      this.sendPageEvent({ type: 'mounted' });
    } else if (this.$route.params?.shortId) {
      payload.proposalId = this.$route.params.shortId;
    } else if (this.$route.params?.token) {
      payload.token = this.$route.params.token;
    } else {
      // eslint-disable-next-line no-console
      console.log('missing parameter in url');
      this.$store.dispatch('showSnackbar', { content: 'Sorry, an error occurred', color: 'error' });
      return;
    }

    if (this.$route.params?.token || this.$route.params?.shortId) {
      this.$store
        .dispatch('proposal/getShareOutputContext', payload)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(async (ctx: any) => {
          const generateDownloads = ['keywords', 'ottAvails'].includes(ctx?.data?.exportType);
          if (!ctx || (!generateDownloads && !(ctx.slides || ctx.proposal))) {
            // eslint-disable-next-line no-console
            console.log('missing data in getShareOutputContext', ctx);
            if (this.exporting) {
              this.sendPageEvent({ type: 'error', message: 'getExportData: no data', exit: true });
            }
            if (this.$route.query.webshot) {
              let msg = 'missing data in context';
              if (!ctx?.data) {
                msg = 'failed to load data';
              } else if (!ctx?.data?.slides) {
                msg = 'failed to load slides ';
              } else if (!ctx?.data?.proposal) {
                msg = 'failed to load proposal';
              }
              // eslint-disable-next-line no-console
              console.log('webshot failure: ' + msg);
            }
            this.$store.dispatch('showSnackbar', { content: 'Sorry, an error occurred', color: 'error' });
            return;
          }
          this.proposal = ctx.data?.proposal || null;

          if (!generateDownloads) {
            this.$store.dispatch('output/cloneOutput');
          }

          if (this.$route.query.webshot) {
            this.loading = false;
            setTimeout(() => {
              // eslint-disable-next-line no-console
              console.log('webshot ready');
            }, 1000);
          } else {
            this.loading = false;
            setTimeout(() => {
              if (this.exporting) {
                this.startExport();
              }
            }, 2000);
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log('error', 'getShareOutputContext', err);
          if (this.$route.query.webshot) {
            // eslint-disable-next-line no-console
            console.log('webshot failure ' + err.message);
          }
        });
    } else if (this.$route.query.webshot) {
      // eslint-disable-next-line no-console
      console.log('webshot failure: no token');
    }
    document.body.classList.add('hide-feedback');
  },

  beforeDestroy(): void {
    document.body.classList.remove('hide-feedback');
  },

  methods: {
    isElementInViewport(el) {
      var rect = el.$el.getBoundingClientRect();
      return rect.top + 10 >= 0 && rect.bottom - 10 <= (window.innerHeight || document.documentElement.clientHeight);
    },
    cssClasses(index: number): string {
      if (this.exporting) {
        return 'exportSlide';
      }
      if (index !== 0) {
        return 'mt-4';
      }
      return '';
    },
    startExport() {
      // const app = document.getElementById('app');
      // app.style.width = `${this.exportingPageWidth}px`;
      // app.style.height = `${this.exportingPageHeight}px`;
      // app.style.overflow = `scroll`;
      // app.style.zoom = `.5`;

      const slides = document.getElementsByClassName('exportSlide');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Array.from(slides).forEach((slide: any) => {
        slide.style.width = `${this.exportingPageWidth}px`;
        slide.style.overflowX = 'hidden';
        slide.style.height = `${this.exportingPageHeight}px`;
        slide.style.overflowY = 'hidden';
        // slide.style.padding = '50px 0 50px 0 !important';
      });
      this.sendPageEvent({
        type: 'startExport',
        // slideCount: this.slidePages.length,
        slideCount: this.slides.length,
      });
    },
    resetAndRenderSlideMap(slideId: string) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.renderSlideMap(slideId);
      }, 250);
    },
    renderSlideMap(slideId: string) {
      this.currentScreenshotSlideId = slideId;
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).renderedSlideIDs[slideId]) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).renderedSlideIDs[slideId] = 'timedout';

        this.sendPageEvent({
          type: 'mapReady',
          data: slideId,
          timedout: true,
        });
      }, 20000);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentRendered(slideId: string, event: any) {
      if (slideId !== this.currentScreenshotSlideId) {
        // eslint-disable-next-line no-console
        console.log('warning, unexpected map type render event:', slideId, event);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).renderedSlideIDs[slideId] === 'timedout') {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).renderedSlideIDs[slideId]) {
        // eslint-disable-next-line no-console
        console.log('warning, map already rendered', slideId);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).renderedSlideIDs[slideId] = true;
      this.waitForRender(() => {
        this.sendPageEvent({
          type: 'mapReady',
          data: slideId,
        });
      }, 200);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sendPageEvent(event: any) {
      // console.log('sendPageEvent test', event);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w: any = window as any;

      if (!w) {
        return;
      }
      // _eventFromPage is registered by puppeteer into this 'browser', to receive events from this page
      if (!w._eventFromPage) {
        this.hookBrowserDebug();
      }
      if (!w._eventToPage) {
        // bind receiving events from puppeteer into this page
        w._eventToPage = this.receiveServerEvent;
      }

      w._eventFromPage(event);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    receiveServerEvent(event: any) {
      try {
        if (!event) {
          this.sendPageEvent({
            type: 'debug',
            data: 'empty event from server to browser',
          });
          return;
        }

        if (event.renderSlides === 'all') {
          if (event.type === 'xls' || event.type === 'xlsx') {
            this.sendPageEvent({
              type: 'dataReady',
              data: this.proposal,
            });
          } else {
            this.waitForRender(() => {
              this.sendPageEvent({
                type: 'slidesReady',
                data: this.proposal,
              });
            }, 4000); // now we have maps
          }
          return;
        }

        if (event.renderSlideMap) {
          this.resetAndRenderSlideMap(event.renderSlideMap);
          // this.renderSlideMap(event.renderSlideMap);
          return;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`receiveServerEvent error ${err.message} ${JSON.stringify(event)}`);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    waitForRender(callback: any, delay: any): void {
      // trying different tricks of nextTicks and setTimeout
      // to find a safe, but as fast as possible
      // way to ensure the page is rendered
      // before telling the server to process
      if (!delay || typeof delay !== 'number') {
        delay = 1;
      }
      this.$nextTick(() => {
        setTimeout(callback, delay);
      });
    },
    hookBrowserDebug() {
      this.browserDebug = true;

      // to debug something outside of the export process
      // here, loading the first template when the page is ready
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fakeServerHandler = (event: any) => {
        if (!event) {
          // eslint-disable-next-line no-console
          console.log('fakeServerHandler, no event');
          return;
        }

        // eslint-disable-next-line no-console
        console.log('fakeServerHandler event received', event);

        // fake latency
        setTimeout(() => {
          switch (event.type) {
            case 'debug':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler debug fired', event.data || event);
              break;
            case 'mounted':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler mounted fired');
              break;
            case 'error':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler error fired', event);
              break;
            case 'startExport':
              w._eventToPage({
                renderSlides: 'all',
                type: 'xls',
                // type: 'pptx',
                // type: 'pdf',
              });
              break;
            case 'screenshotsNeeded':
              // eslint-disable-next-line no-console
              console.log('screenshotsNeeded', event);
              w._eventToPage({
                renderSlideMap: event.needToScreenshotMaps[0]._id,
              });
              break;
            case 'mapReady':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler mapReady fired');
              break;
            case 'slidesReady':
              this.$store.dispatch('showSnackbar', { content: 'export done', color: 'success' });
              break;
            default:
              // eslint-disable-next-line no-console
              console.log('unhandled fakeServerHandler', event);
              break;
          }
        }, 500);
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w: any = window as any;
      w._eventFromPage = fakeServerHandler;
    },
  },
});
